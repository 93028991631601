@import "sidebar.scss";
@import "forms.scss";  
@import "btn.scss";
@import "animate.scss";
@import "material.scss";
@import "modal.scss";
@import "grid.scss";
@import "datepicker.scss";

html,body{
    font-family: map-get($fonts,one);
    font-weight: 500;
    height: 100%;
    line-height: 20px;
}
h1,h2,h3{
    font-family: "Montserrat" !important;
}
.box-sombra{
    background: #FFF;  
    box-shadow: 5px 5px 20px 0 #d4d4d4;
    padding: 20px;
}
.center-table{
    display: table;
    margin: 0 auto;
}
.page{
    padding: 70px 15px 100px;
}
.page-title-h1{
    font-size: 16px;
    color: map-get($colors,two);
    font-weight: 600;
}
.container-fluid {
    padding-right: 0!important;
    padding-left: 0!important;
} 
.page-link{
    color: map-get($colors,one);
}
.row-center{
    align-items: center;
}
p{
    margin: 0;
}
.linha{
    background: #eee;
    margin: 10px 0;
    display: table;
    width: 100%;
    height: 1px;
}
.title-h3,.title-h4{
    margin: 10px 0;
    font-size: 17px;
    color: map-get($colors,two);
    font-weight: 600;
}
.title-h4{
    font-size: 15px;
}
article h2{
    font-size: 20px;
}
/** combo **/
.dm-combo-loader{
    display: table;
    width: 100%;
    position: relative;
}
.dm-combo-loader .btn-loader{
    padding: 3px;
    float: right;
    cursor: pointer;
    line-height: 10px;
    position: absolute;
    top: 0;
    right: 0;
}
.dm-combo-loader .info{
    float: left;
    font-size: 12px;
    margin: 0;
    padding: 5px;
    width: 87%;
    border-radius: 0;
}
.is-button-add{
    display: flex;
    align-items: center;
}
.is-button-add .combo-content-input{
    padding: 0 5px 0 0;
    position: relative;
    cursor: pointer !important; 
    margin: 0 0 5px 0;   
}
.is-button-add .content-button{
    position: relative;
    top: 4px;
}
.combo-content-input{
    position: relative;
}
.loader-message-combo{
    position: absolute;
    top: 0px;
    font-size: 12px;
    width: 100%;
    background: map-get($colors,two);
    color: #FFF;
    padding: 12px;
    border-radius: 3px;  
}
.page-body-auth .topo-layout{  
    display: none !important; 
}
.page-body-auth mat-drawer-content{
    margin: 0 !important;
}
.mat-drawer-container-has-open .page-cart .page-footer{
    padding-left: 250px !important;
}
.page-cart .page-footer button i{
    margin: 5px;
}
.bloco-table{
    display: table;
}
/** card **/
mat-card{
    padding: 0;
}
mat-card-title span{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
}
mat-card mat-card-content{
    padding: 20px; 
}
.ngx-gallery { display: inline-block; margin-bottom: 20px; }

/** dashboard **/
.dashboard .dashboard-title{
    display: flex;
    align-items: center;
}
.dashboard .dashboard-title h3{
    flex: 1;
    margin-top: 0;
}
.dashboard .dashboard-refresh{
    padding: 5px;
}
.dashboard .dashboard-refresh i{
    font-size: 18px;
}
.bg-blue{
    background: map-get($colors,one);
    border-radius: 5px;
    padding: 5px 10px;
    color: #FFF;
    font-size: 12px;
    min-width: 50px;
}
.bg-green{
    background: map-get($colors,two);
    border-radius: 5px;
    padding: 5px 10px;
    color: #FFF;
    font-size: 12px;
    min-width: 50px;
}
/** payment link status **/
.payment-link-status-1,.payment-link-status-2,.payment-link-status-3{
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    color: #FFF;
}
.payment-link-status-1{
    background: #c93a0d;
}
.payment-link-status-2{
    background: #6aaf10;
}
.payment-link-status-3{
    background: #e7072e;
}